<template>
  <div class="container new-copy-job">
    <el-form id="form" label-position="top">
      <el-row>
        <el-col :span="12" :xs="24" class="source-col">
          <div class="source-container">
            <div class="heading-container">
              <el-row>
                <el-col :span="2" class="hidden-xs-only"><el-page-header @back="back" /></el-col>
                <el-col :span="20" :xs="24"><h2 class="subheading centered">Source line items</h2></el-col>
                <el-col :span="2" :xs="24"></el-col>
              </el-row>
              <el-row>
                <el-col><p class="description centered">Select the line items you wish to copy</p></el-col>
              </el-row>
            </div>
            <el-divider class="hidden-sm-and-down"></el-divider>
            <el-row>
              <el-col :span="12" :xs="24">
                <el-form-item label="Advertiser">
                  <el-select class="stretched octi-select" ref="advertiserSelect" v-model="form.sourceAdvertiser" filterable placeholder="Select advertiser" value-key="id">
                    <el-option v-for="advertiser in advertisers" :key="advertiser.id" :label="advertiser.name" :value="advertiser"> </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12" :xs="24">
                <el-form-item label="Campaign">
                  <el-select class="stretched octi-select" ref="campaignSelect" v-model="form.sourceCampaign" filterable placeholder="Select campaign" value-key="id">
                    <el-option v-for="campaign in campaigns" :key="campaign.id" :label="campaign.name" :value="campaign"> </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="lineItems.length > 0">
              <el-col>
                <p class="octi-label">Line items</p>
              </el-col>
            </el-row>
            <el-row>
              <el-checkbox-group class="stretched" v-model="form.sourceLineItems">
                <el-row v-for="(lineItem, index) in lineItems" :key="index">
                  <el-col>
                    <el-checkbox class="stretched line-item-checkbox" :label="lineItem" border>
                      {{ lineItem.name }}
                    </el-checkbox>
                  </el-col>
                </el-row>
              </el-checkbox-group>
            </el-row>
            <el-row>
              <el-col>
                <el-button type="primary" class="toggle-line-items stretched octi-button" @click="toggleLineItems">{{ toggleLineItemsName }}</el-button>
              </el-col>
            </el-row>
          </div>
        </el-col>
        <el-divider class="hidden-sm-and-up"></el-divider>
        <el-col :span="12" :xs="24" class="target-col">
          <div class="target-container">
            <div class="heading-container">
              <el-row>
                <el-col>
                  <h2 class="subheading centered">Target campaign</h2>
                </el-col>
              </el-row>
              <el-row>
                <el-col>
                  <p class="description centered">Create a new campaign with the selected line items</p>
                </el-col>
              </el-row>
            </div>
            <el-divider class="hidden-sm-and-down"></el-divider>
            <el-row>
              <el-col :span="12" :xs="24">
                <el-form-item label="Advertiser">
                  <el-select class="stretched octi-select" v-model="form.targetAdvertiser" filterable placeholder="Select advertiser" value-key="id">
                    <el-option v-for="advertiser in advertisers" :key="advertiser.id" :label="advertiser.name" :value="advertiser"> </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12" :xs="24">
                <el-form-item label="Campaign name">
                  <el-input v-model="form.targetCampaignName" placeholder="Campaign name" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12" :xs="24">
                <el-form-item label="Start date">
                  <el-date-picker
                    class="stretched"
                    v-model="form.targetStartTime"
                    format="DD.MM.YYYY HH:mm"
                    value-format="YYYY-MM-DDTHH:mm"
                    type="datetime"
                    placeholder="Start date"
                    style="width: 100%"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12" :xs="24">
                <el-form-item label="End date">
                  <el-date-picker class="stretched" v-model="form.targetEndTime" format="DD.MM.YYYY HH:mm" value-format="YYYY-MM-DDTHH:mm" type="datetime" placeholder="End date" style="width: 100%">
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12" :xs="24">
                <el-form-item label="Manager">
                  <el-select class="stretched octi-select" v-model="form.targetManager" filterable placeholder="Select manager" value-key="id">
                    <el-option v-for="manager in managers" :key="manager.id" :label="manager.fullName" :value="manager"> </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12" :xs="24">
                <el-form-item label="Co-manager">
                  <el-select class="stretched octi-select" v-model="form.targetCoManager" filterable placeholder="Select co-manager" value-key="id">
                    <el-option v-for="manager in managers" :key="manager.id" :label="manager.fullName" :value="manager"> </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row
              ><el-col :span="9" :xs="16">
                <el-form-item label="Campaign budget (NOK)">
                  <el-input type="number" v-model="form.targetBudget" min="0" placeholder="Campaign budget" />
                </el-form-item>
              </el-col>
              <el-col :span="3" :xs="8">
                <el-button type="primary" class="stretched multiply-btn octi-button" @click="multiplyBudget(0.75)">x 0.75</el-button>
              </el-col>
              <el-col :span="12" :xs="24">
                <el-row>
                  <el-form-item class="stretched budget-pacing" label="Spend budget">
                    <el-col :span="8" :xs="24" class="budget-pacing-radio first-radio">
                      <el-radio class="stretched" v-model="form.targetPacing" label="Evenly" border> Evenly </el-radio>
                    </el-col>
                    <el-col :span="8" :xs="24" class="budget-pacing-radio">
                      <el-radio class="stretched" v-model="form.targetPacing" label="Ahead" border> Ahead </el-radio>
                    </el-col>
                    <el-col :span="8" :xs="24" class="budget-pacing-radio last-radio">
                      <el-radio class="stretched" v-model="form.targetPacing" label="Asap" border> ASAP </el-radio>
                    </el-col>
                  </el-form-item>
                </el-row>
              </el-col>
            </el-row>
            <el-row>
              <el-col>
                <el-button type="primary" id="create-copy-job-btn" class="stretched octi-button" @click="createCopyJob">Create copy job</el-button>
              </el-col>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import APIService from "@/services/APIService.js";
import router from "@/router/index.js";
import "element-plus/theme-chalk/display.css";
const { DateTime } = require("luxon");

export default {
  name: "CopyTool",
  data: () => ({
    advertisers: [{ id: 2029793, name: "Templates" }],
    campaigns: [{ id: 1610220, name: "Eiendomsmegler 1 MN Template" }],
    lineItems: [
      { id: 1605876, name: "Adressa - 980 x 600" },
      { id: 1605632, name: "Adressa - Eldre par med voksne barn" },
      { id: 1605635, name: "Adressa - Familier med små barn" },
      { id: 1605627, name: "Adressa - Familier uten barn" },
      { id: 1605865, name: "Audience Pixel" },
      { id: 1694595, name: "Premium Norge Real Estate - Trondheim" },
      { id: 1694621, name: "Premium Norge Real Estate - Trøndelag" },
      { id: 1605753, name: "Retargeting" },
      { id: 1605493, name: "Schibsted - Eldre par med voksne barn" },
      { id: 1605371, name: "Schibsted - Familier med små barn" },
      { id: 1605395, name: "Schibsted - Familier uten barn" },
      { id: 1605785, name: "White list - Alle familietyper" },
    ],
    managers: [
      { id: 38055, fullName: "Anders Bolme Ree" },
      { id: 38479, fullName: "Maren Aarstad" },
    ],
    form: {
      sourceAdvertiser: { id: 2029793, name: "Templates" },
      sourceCampaign: { id: 1610220, name: "Eiendomsmegler 1 MN Template" },
      sourceLineItems: [],
      targetAdvertiser: null,
      targetCampaignId: null,
      targetCampaignName: null,
      targetBudget: null,
      targetPacing: "Ahead",
      targetStartTime: DateTime.now().startOf("day").toFormat("yyyy-MM-dd'T'HH:mm"),
      targetEndTime: DateTime.now().endOf("day").toFormat("yyyy-MM-dd'T'HH:mm"),
      targetManager: { id: 38055, fullName: "Anders Bolme Ree" },
      targetCoManager: { id: 38479, fullName: "Maren Aarstad" },
    },
  }),
  props: {},
  created() {
    this.form.sourceLineItems = this.lineItems;
    this.getAdvertisers();
    this.getManagers();
  },
  methods: {
    getAdvertisers() {
      APIService.getAdvertisers()
        .then((response) => {
          if (response.status === 200) {
            this.advertisers = response.data.data.advertisers;
          }
        })
        .catch((error) => console.warn(error));
    },
    getCampaigns(advertiserId) {
      APIService.getCampaigns(advertiserId)
        .then((response) => {
          if (response.status === 200) {
            this.campaigns = response.data.data.campaigns;
          }
        })
        .catch((error) => console.warn(error));
    },
    getLineItems(campaignId) {
      APIService.getLineItems(campaignId)
        .then((response) => {
          if (response.status === 200) {
            this.lineItems = response.data.data.lineItems;
            this.form.sourceLineItems = this.lineItems;
          }
        })
        .catch((error) => console.warn(error));
    },
    getManagers() {
      APIService.getManagers()
        .then((response) => {
          if (response.status === 200) {
            this.managers = response.data.data.managers;
          }
        })
        .catch((error) => console.warn(error));
    },
    createCopyJob() {
      var formData = new FormData();
      formData.append("object.sourceAdvertiserId", this.form.sourceAdvertiser.id);
      formData.append("object.sourceAdvertiserName", this.form.sourceAdvertiser.name);
      formData.append("object.sourceCampaignId", this.form.sourceCampaign.id);
      formData.append("object.sourceCampaignName", this.form.sourceCampaign.name);
      formData.append("object.targetAdvertiserId", this.form.targetAdvertiser.id);
      formData.append("object.targetAdvertiserName", this.form.targetAdvertiser.name);
      formData.append("object.targetCampaignId", this.form.targetCampaignId);
      formData.append("object.targetCampaignName", this.form.targetCampaignName);
      formData.append("object.targetBudget", this.form.targetBudget);
      formData.append("object.targetPacing", this.form.targetPacing);
      formData.append("object.targetStartTime", this.form.targetStartTime);
      formData.append("object.targetEndTime", this.form.targetEndTime);
      formData.append("object.targetManagerId", this.form.targetManager.id);
      formData.append("object.targetManagerName", this.form.targetManager.fullName);
      formData.append("object.targetCoManagerId", this.form.targetCoManager.id);
      formData.append("object.targetCoManagerName", this.form.targetCoManager.fullName);

      if (this.form.sourceLineItems.length > 0) {
        formData.append("object.sourceLineItems", JSON.stringify(this.form.sourceLineItems));
      }

      APIService.createCopyJob(formData)
        .then((response) => {
          if (response.status === 200) {
            router.push({
              name: "CopyJob",
              params: { copyJobId: response.data.data.copyJobId, startCopyJob: true },
            });
          }
        })
        .catch((error) => console.warn(error));
    },
    back() {
      router.push({
        name: "CopyJobs",
      });
    },
    toggleLineItems() {
      if (this.allLineItemsSelected) {
        this.form.sourceLineItems = [];
      } else {
        this.form.sourceLineItems = this.lineItems;
      }
    },
    multiplyBudget(value) {
      if (this.form.targetBudget > 0) {
        this.form.targetBudget = Math.round(this.form.targetBudget * value * 100) / 100;
      }
    },
  },
  computed: {
    allLineItemsSelected() {
      return this.form.sourceLineItems.length == this.lineItems.length ? true : false;
    },
    toggleLineItemsName() {
      return this.allLineItemsSelected ? "Deselect all line items" : "Select all line items";
    },
  },
  watch: {
    "form.sourceAdvertiser": function (newAdvertiser) {
      this.lineItems = [];
      this.form.sourceCampaign = null;
      this.getCampaigns(newAdvertiser.id);
      this.$nextTick(() => this.$refs.campaignSelect.focus());
    },
    "form.sourceCampaign": function (newCampaign) {
      if (this.form.sourceCampaign) {
        this.getLineItems(newCampaign.id);
      }
    },
  },
};
</script>

<style scoped>
.new-copy-job #form .stretched {
  width: 100%;
}
.new-copy-job #form .centered {
  text-align: center;
}
.new-copy-job .multiply-btn {
  margin-top: 30px;
}
.new-copy-job .el-col {
  padding: 10px;
}
.new-copy-job .subheading {
  margin: 0px;
  padding: 0px;
}
.new-copy-job .description {
  margin: 0px;
  padding: 0px;
}

.new-copy-job .el-col {
  padding: 5px 10px !important;
}

.new-copy-job .octi-label {
  margin-bottom: 2px;
  line-height: 22px;
  font-size: 14px;
  margin-top: 0px;
  color: #606266;
}

.new-copy-job .line-item-actions {
  margin-top: 30px;
  margin-bottom: 30px;
}

.new-copy-job #create-copy-job-btn {
  margin-top: 10px;
}
.new-copy-job .toggle-line-items {
  margin-top: 30px;
}

@media only screen and (max-width: 600px) {
  .new-copy-job .line-item-checkbox.el-checkbox__label {
    font-size: 50px !important;
  }
  .new-copy-job .budget-pacing-radio {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .new-copy-job .heading-container {
    margin-bottom: 25px;
  }
}

@media only screen and (min-width: 600px) {
  .new-copy-job .budget-pacing-radio {
    padding-top: 0px !important;
  }
  .new-copy-job .first-radio {
    padding-left: 0px !important;
  }
  .new-copy-job .last-radio {
    padding-right: 0px !important;
  }
  .source-col {
    padding-right: 15px !important;
  }
  .target-col {
    padding-left: 15px !important;
  }
}
</style>
