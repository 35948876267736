<template>
  <el-container>
    <el-main><router-view /></el-main>
  </el-container>
</template>

<style>
html {
  background-color: #262626;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.container {
  border-radius: 12px;
  background-color: white;
  margin: 0 auto;
  padding: 20px;
}

@media only screen and (max-width: 600px) {
  .container {
    width: 88%;
    margin-top: 3% !important;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 600px) {
  .container {
    width: 94%;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 768px) {
  .container {
    width: 94%;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 992px) {
  .container {
    width: 94%;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 1200px) {
  .container {
    width: 1500px;
    margin: 0 auto;
  }
}

.octi-button {
  color: white;
  background-color: #de0284;
  border-color: #de0284;
}

.octi-button:hover {
  color: white;
  background-color: #db0e86;
  border-color: #de0284;
}

.octi-button:focus {
  color: white;
  background-color: #db0e86;
  border-color: #de0284;
}

.octi-select .el-input__inner:focus {
  border-color: #dcdfe6 !important;
}

.el-select-dropdown__item.selected {
  color: #262626;
}

.el-checkbox.is-bordered.is-checked,
.el-radio.is-bordered.is-checked {
  border-color: #dcdfe6;
}

.el-radio__input.is-checked .el-radio__inner {
  background: #db0e86;
  border-color: #db0e86;
  color: #262626;
}

.el-radio__input.is-checked + .el-radio__label {
  color: #606266;
}

.el-radio__inner:hover {
  border-color: #db0e86;
}

.el-checkbox__inner:hover {
  border-color: #db0e86;
}

.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #606266;
}

.el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #db0e86;
  border-color: #db0e86;
}

.el-select-dropdown__item.selected {
  /* background-color: #262626; */
  color: #db0e86;
  /* font-weight: 100; */
}

.el-form-item.el-form-item--default {
  margin-bottom: 8px;
}

.el-input__inner:focus {
  outline: 0;
  border-color: #dcdfe6;
}

.el-page-header__left::after {
  display: none;
}
</style>
