import axios from "axios";

let baseURL = process.env.VUE_APP_API_BASE_URL;

const Service = axios.create({
  baseURL: baseURL,
});

Service.interceptors.request.use(config => {
  config.headers['login-token'] = localStorage.getItem("loginToken");
	return config
})

Service.interceptors.response.use(undefined, err => {
	return new Promise(function(resolve, reject) {
		if (err.response.status === 401) {
      if (localStorage.getItem('loginToken') !== null) {
				localStorage.removeItem('loginToken');
			}
		}
		throw err
	})
})

export default {
  createCopyJob(formData) {
    return Service.post("copy-jobs", formData);
  },
  getCopyJobs() {
    return Service.get("copy-jobs");
  },
  getCopyJob(copyJobId) {
    return Service.get("copy-jobs/" + copyJobId);
  },
  startCopyJob(copyJobId) {
    return Service.post("copy-jobs/" + copyJobId + "/start");
  },
  getAdvertisers() {
    return Service.get("advertisers");
  },
  getCampaigns(advertiserId) {
    return Service.get("advertisers/" + advertiserId + "/campaigns");
  },
  getLineItems(campaignId) {
    return Service.get("campaigns/" + campaignId + "/line-items");
  },
  getManagers() {
    return Service.get("managers");
  },
  login(formData) {
    return Service.post("login", formData);
  },
  logout() {
    return Service.post("logout");
  } 
};
