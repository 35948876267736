<template>
  <div class="login-container">
    <div class="logo-container">
      <img class="logo" src="@/assets/octi-logo.svg" />
    </div>
    <div class="form-container">
      <el-form :model="form" label-position="top">
        <el-form-item label="Email">
          <el-input ref="email" v-model="form.email" @keyup.enter="login" />
        </el-form-item>
        <el-form-item label="Password">
          <el-input type="password" v-model="form.password" @keyup.enter="login" />
        </el-form-item>
        <div class="action-container">
          <el-form-item>
            <el-button class="login-button octi-button" type="primary" @click="login"> Log in </el-button>
          </el-form-item>
        </div>
      </el-form>
      <el-alert class="login-alert" v-if="alert" :title="alert" type="error" show-icon :closable="false" />
    </div>
  </div>
</template>

<script>
import APIService from "@/services/APIService.js";
import router from "@/router/index.js";

export default {
  name: "Login",

  data: () => ({
    form: {
      email: "",
      password: "",
    },
    alert: "",
  }),
  mounted() {
    this.$nextTick(() => this.$refs.email.focus());
  },
  methods: {
    login() {
      this.alert = "";
      var formData = new FormData();
      formData.append("email", this.form.email);
      formData.append("password", this.form.password);
      return APIService.login(formData)
        .then((response) => {
          if (response.data.status == "SUCCESS") {
            let loginToken = response.data.data.loginToken.token;
            localStorage.setItem("loginToken", loginToken);
            this.$router.push({ name: "CopyJobs" });
          } else {
            this.alert = response.data.message;
          }
          return response;
        })
        .catch((error) => error.response);
    },
  },
  computed: {},
};
</script>

<style>
.login-container {
  border-radius: 12px;
  padding: 20px;
  background-color: white;
}

@media only screen and (max-width: 600px) {
  .login-container {
    width: 88%;
    margin-top: 3% !important;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 600px) {
  .login-container {
    width: 400px;
    background-color: white;
    margin: 0 auto;
  }
}

.login-container .logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
.login-container .logo {
  width: 60%;
}
.login-container .action-container {
  align-items: center;
  justify-content: center;
  text-align: center;
}
.login-button,
.remember-me {
  width: 100%;
  margin-top: 20px;
}

.login-container .login-alert {
  color: red;
  background-color: white;
  height: 30px;
  margin: 20px 0px 5px 0px;
  border: 1px solid red;
}
</style>
