<template>
  <div class="container copy-job" v-if="copyJob">
    <el-card shadow="never">
      <template #header>
        <div class="card-header">
          <el-row>
            <el-col :span="4" class="hidden-xs-only back-container"><el-page-header @back="back" /></el-col>
            <el-col :span="16" :xs="24" class="title-container">
              <h2>{{ cardTitle }}</h2>
            </el-col>
            <el-col :span="4" :xs="24" class="action-container" v-if="copyJob.targetCampaignId">
              <el-button type="primary" class="octi-button" @click="openInAdform()">Open in Adform</el-button>
            </el-col>
          </el-row>
        </div>
      </template>
      <el-row>
        <el-col :span="2" :xs="24">
          <p class="info-title">Source</p>
        </el-col>
        <el-col :span="22" :xs="24">
          <p class="info-value">{{ copyJob.sourceAdvertiserName }}: {{ copyJob.sourceCampaignName }}</p>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="2" :xs="24">
          <p class="info-title">Created</p>
        </el-col>
        <el-col :span="4" :xs="24">
          <p class="info-value">{{ copyJob.createdAt }}</p>
        </el-col>
        <el-col :span="2" :xs="24">
          <p class="info-title">Status</p>
        </el-col>
        <el-col :span="16" :xs="24">
          <p class="info-value">{{ copyJob.status }}</p>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="2" :xs="24">
          <p class="info-title">Start time</p>
        </el-col>
        <el-col :span="4" :xs="24">
          <p class="info-value">{{ copyJob.targetStartTime }}</p>
        </el-col>
        <el-col :span="2" :xs="24">
          <p class="info-title">End time</p>
        </el-col>
        <el-col :span="16" :xs="24">
          <p class="info-value">{{ copyJob.targetEndTime }}</p>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="2" :xs="24">
          <p class="info-title">Budget</p>
        </el-col>
        <el-col :span="4" :xs="24">
          <p class="info-value">{{ copyJob.targetBudget }} NOK</p>
        </el-col>
        <el-col :span="2" :xs="24">
          <p class="info-title">Pacing</p>
        </el-col>
        <el-col :span="16" :xs="24">
          <p class="info-value">{{ copyJob.targetPacing }}</p>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="2" :xs="24">
          <p class="info-title">Manager</p>
        </el-col>
        <el-col :span="4" :xs="24">
          <p class="info-value">{{ copyJob.targetManagerName }}</p>
        </el-col>
        <el-col :span="2" :xs="24">
          <p class="info-title">Co-manager</p>
        </el-col>
        <el-col :span="16" :xs="24">
          <p class="info-value">{{ copyJob.targetCoManagerName }}</p>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <h2>Line items</h2>
      <template v-for="lineItem in copyJob.sourceLineItems" :key="lineItem.id">
        <el-collapse>
          <el-collapse-item>
            <template #title>
              <el-result class="status-icon" :icon="getStatusIcon(lineItem.status)"></el-result>
              {{ lineItem.name }}
            </template>
            <pre class="status-message">{{ getStatusMessage(lineItem) }}</pre>
            <!-- <div>{{ getStatusMessage(lineItem) }}</div> -->
            <!-- <pre>{{ JSON.stringify(JSON.parse(lineItem.errorMessage), null, 2) }}</pre> -->
          </el-collapse-item>
        </el-collapse>
      </template>
    </el-card>
  </div>
</template>

<script>
import APIService from "@/services/APIService.js";
import router from "@/router/index.js";
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";

export default {
  components: {
    VueJsonPretty,
  },
  props: ["copyJobId", "startCopyJob"],
  name: "CopyJob",
  data: () => ({
    copyJob: null,
    test: "",
  }),
  mounted() {
    window.setInterval(() => {
      this.getCopyJob(this.copyJobId);
    }, 1000);
    if (this.startCopyJob) {
      APIService.startCopyJob(this.copyJobId);
    }
  },
  methods: {
    getCopyJob(copyJobId) {
      APIService.getCopyJob(copyJobId)
        .then((response) => {
          if (response.status === 200) {
            this.copyJob = response.data.data.copyJob;
          }
        })
        .catch((error) => console.warn(error));
    },
    openInAdform() {
      window.open("https://flow.adform.com/campaign/" + this.copyJob.targetCampaignId + "/overview", "_blank").focus();
    },
    getStatusIcon(status) {
      if (status == "Waiting") {
        return "info";
      } else if (status == "Completed") {
        return "success";
      } else {
        return "error";
      }
    },
    getStatusMessage(lineItem) {
      if (lineItem.status == "Waiting") {
        return "Line item pending creation";
      } else if (lineItem.status == "Completed") {
        return "Line item successfully created";
      } else {
        return JSON.stringify(JSON.parse(lineItem.errorMessage), null, 2);
      }
    },
    back() {
      router.push({
        name: "CopyJobs",
      });
    },
  },
  computed: {
    cardTitle: function () {
      return this.copyJob.targetAdvertiserName + " - " + this.copyJob.targetCampaignName;
    },
  },
};
</script>
<style>
.info-title {
  font-weight: bold;
  margin: 5px;
}

.info-value {
  margin: 5px;
}
.action-container {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}
.title-container {
  text-align: center;
}
.back-container {
  display: flex;
  align-items: center;
}
.status-icon .el-result__icon {
  display: flex !important;
}
.status-icon .icon {
  height: 20px !important;
}
.status-icon {
  width: 0px;
  padding: 0px 20px 0px 9px;
}
.status-message {
  margin: 8px 0px 0px 0px;
}

.el-card {
  border: none !important;
}

@media only screen and (max-width: 600px) {
  .copy-jobs {
    text-align: center;
  }
  .copy-jobs .search {
    max-width: 100%;
    margin: 0px 0px 20px 0px;
  }
  .octi-button {
    width: 100%;
  }

  .info-value,
  .info-title {
    font-size: 13px;
  }
}

@media only screen and (min-width: 600px) {
  .copy-jobs .action-container {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    align-content: stretch;
  }
}
</style>
