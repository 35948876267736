<template>
  <div class="container copy-jobs">
    <el-row>
      <el-col :span="12" :xs="24">
        <h1>Copy jobs</h1>
      </el-col>
      <el-col :span="12" :xs="24" class="action-container">
        <el-row gutter="20">
          <el-col :span="12" :xs="24">
            <el-input v-model="search" class="search" clearable placeholder="Search" />
          </el-col>
          <el-col :span="12" :xs="24">
            <el-button class="new-copy-job octi-button stretched" @click="newCopyJob"> New copy job </el-button>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-divider class="hidden-xs-only" />
    <el-row>
      <el-col class="desktop-copy-jobs">
        <el-table :data="filteredCopyJobs" style="width: 100%" :default-sort="{ prop: 'createdAt', order: 'descending' }" @current-change="openCopyJob">
          <el-table-column width="150" prop="createdAt" label="Created" sortable />
          <el-table-column width="150" prop="user" label="User" sortable />
          <el-table-column min-width="150" prop="copiedFrom" label="Source" sortable />
          <el-table-column min-width="150" prop="copiedTo" label="Target" sortable />
        </el-table>
      </el-col>
      <el-col class="mobile-copy-jobs">
        <div class="copy-job" v-for="copyJob in filteredCopyJobs" :key="copyJob.id" @click="openCopyJob(copyJob)">
          <el-row>
            <el-col span="24">{{ copyJob.createdAt }}</el-col>
          </el-row>
          <el-row>
            <el-col span="24">{{ copyJob.copiedTo }}</el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import APIService from "@/services/APIService.js";
import router from "@/router/index.js";
import "element-plus/theme-chalk/display.css";

export default {
  name: "CopyJobs",
  data: () => ({
    copyJobs: [],
    search: "",
    isMobile: window.matchMedia("only screen and (max-width: 760px)").matches,
  }),
  created() {
    this.getCopyJobs();
  },
  methods: {
    getCopyJobs() {
      APIService.getCopyJobs()
        .then((response) => {
          if (response.status === 200) {
            this.copyJobs = response.data.data.copyJobs;
          }
        })
        .catch((error) => console.warn(error));
    },
    newCopyJob() {
      router.push({ name: "NewCopyJob" });
    },
    openCopyJob(copyJob) {
      router.push({ name: "CopyJob", params: { copyJobId: copyJob.id } });
    },
  },
  computed: {
    filteredCopyJobs: function () {
      if (this.copyJobs) {
        return this.copyJobs.filter(
          (copyJob) =>
            copyJob.createdAt.toLowerCase().includes(this.search.toLowerCase()) ||
            copyJob.copiedFrom.toLowerCase().includes(this.search.toLowerCase()) ||
            copyJob.copiedTo.toLowerCase().includes(this.search.toLowerCase()) ||
            copyJob.user.toLowerCase().includes(this.search.toLowerCase())
        );
      }
    },
  },
};
</script>
<style>
.copy-jobs .stretched {
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .copy-jobs {
    text-align: center;
  }
  .copy-jobs .search {
    max-width: 100%;
    margin: 0px 0px 20px 0px;
  }
  .copy-job {
    border: 1px solid #dcdfe6;
    border-radius: 8px;
    padding: 10px;
    cursor: pointer;
    margin-top: 5px;
  }
  .copy-jobs .mobile-copy-jobs {
    display: block;
    margin-top: 20px;
  }
  .copy-jobs .desktop-copy-jobs {
    display: none;
  }
}

@media only screen and (min-width: 600px) {
  .copy-jobs .action-container {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    align-content: stretch;
  }
  .copy-jobs .mobile-copy-jobs {
    display: none;
  }
  .copy-jobs .desktop-copy-jobs {
    display: block;
  }
}
</style>
