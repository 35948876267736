import { createRouter, createWebHistory } from "vue-router";
import APIService from "@/services/APIService.js";

const routes = [
  {
    path: "/",
    name: "Index",
    redirect: "/copy-jobs",
  },
  {
    path: "/copy-jobs",
    name: "CopyJobs",
    component: () => import(/* webpackChunkName: "copyJobs" */ "../views/CopyJobs.vue"),
  },
  {
    path: "/copy-jobs/new",
    name: "NewCopyJob",
    component: () => import(/* webpackChunkName: "newCopyJob" */ "../views/NewCopyJob.vue"),
  },
  {
    path: "/copy-jobs/:copyJobId",
    name: "CopyJob",
    props: true,
    component: () => import(/* webpackChunkName: "copyJob" */ "../views/CopyJob.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import(/* webpackChunkName: "login" */ "../views/Login.vue"),
  },
  {
    path: "/logout",
    beforeEnter: (to, from, next) => {
      APIService.logout()
      localStorage.removeItem("loginToken");
      next({ name: 'Login' })
    },
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  let loginToken = localStorage.getItem("loginToken") ? localStorage.getItem("loginToken") : null
  if (to.name !== 'Login' && !loginToken) {
    next({ name: 'Login' })
  } else if (to.name == 'Login' && loginToken) {
    next({ name: 'CopyJobs'})
  } else {
    next()
  }
})

export default router;
